// react
import { useEffect, useState } from "react"
//
import { useHistory } from "react-router-dom"
// material
import { styled } from "@mui/material/styles"
import { MButton, MSpinner } from "../components/@material-extend"
import { Container, Grid, Typography } from "@mui/material"
// components
import Page from "../components/Page"
import { PromoForm } from "../components/_external-pages/profile"
// hooks
import useAuth from "../hooks/useAuth"
// API
import { activateAPI, getSubscription } from "../network/api"

// ----------------------------------------------------------------------
const RootStyle = styled(Page)(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(5, 0),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

const CardStyle = styled("div")(({ theme }) => ({
  boxShadow: theme.customShadows.primary,
  maxWidth: 456,
  margin: "auto",
  borderRadius: theme.shape.borderRadiusMd,
  background: "linear-gradient(360deg,#ebf4fd,hsla(0,0%,100%,0) 25%)",
  padding: theme.spacing(5, 2.5),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(5, 5),
  },
}))
// ----------------------------------------------------------------------

export default function Profile() {
  const history = useHistory()
  const { user, isAuthenticated, logout } = useAuth()
  const [loading, setLoading] = useState(true)
  const [expired, setExpired] = useState(true)
  const [expiry, setExpiry] = useState("")

  useEffect(() => {
    const date = new Date()
    if (isAuthenticated) {
      //NOTE: this code describes using new APIs
      getSubscription(user.id).then((data) => {
        const { validUntil, role } = data
        setLoading(false)
        if (validUntil && validUntil !== null) {
          setExpiry(new Date(validUntil).toLocaleDateString())
          // user.promo // not retrieved from server
          if (
            Date.parse(new Date(validUntil).toLocaleDateString()) <
            Date.parse(date.toLocaleDateString())
          ) {
            setExpired(false)
            console.log("expired")
          } else {
            console.log("not expired")
            logout()
            let progCode = "personal--"
            if(role === 'class' || role === 'teacher') progCode = "smartboard"
            // if (res.progCode == "yoyo") progCode = "personal--"
            // else progCode = "smartboard"
            window.api.send("toMain", {
              UID: user.id,
              Email: user.email,
              App: validUntil,
              Code: progCode,
              Token: localStorage.getItem("id_token"),
              RefreshToken: localStorage.getItem("refresh_token"),
            })
          }
        } else setExpired(false)
      })
      //NOTE: this code describes using new APIs
      //FIXME: delete code ---
      // logout()
      // window.api.send("toMain", {
      //   UID: user.id,
      //   Email: user.email,
      //   App: "2022-01-21",
      //   Code: "smartboard",
      // })
      //FIXME: delete code ---
      //FIXME: return code ---
      // const req = {
      //   accountId: user.email,
      // }
      // activateAPI(req).then((res) => {
      //   setLoading(false)
      //   if (res.success === "True") {
      //     setExpiry(res.expiry)
      //     user.promo = res.serialNo
      //     if (res.expiry < date.toISOString().slice(0, 10)) {
      //       setExpired(false)
      //     } else {
      //       // console.log("user normal: ", user)
      //       // console.log("expiry", typeof res.expiry) //2022-01-21
      //       logout()
      //       let progCode = ""
      //       if (res.progCode == "yoyo") progCode = "personal--"
      //       else progCode = "smartboard"
      //       window.api.send("toMain", {
      //         UID: user.id,
      //         Email: user.email,
      //         App: res.expiry,
      //         Code: progCode,
      //       })
      //     }
      //   } else setExpired(false)
      // })
      //FIXME: return code ---
    } else if (!isAuthenticated) {
      history.push("/login")
    }
  }, [isAuthenticated, user])

  const handleLogout = () => {
    logout()
  }

  return (
    <RootStyle title="بوابة ضمّة">
      <Container maxWidth="lg">
        <Grid spacing={5} container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h2" color="primary.main">
              مرحباً بك في عالم ضمّة
            </Typography>
            <Typography
              variant="body1"
              color="primary.main"
              sx={{ textAlign: "center" }}
            >
              {user?.email}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <MSpinner />
            ) : expired ? (
              <CardStyle>
                <Typography variant="body1" color="primary.main">
                  لديك اشتراك صالح لمدّة: {expiry}
                </Typography>
              </CardStyle>
            ) : (
              <CardStyle>
                <PromoForm
                  onSent={() => {
                    setExpired(true)
                    // console.log('user: ', user)
                    // let progCode = ""
                    // if (user.progCode == "yoyo") progCode = "personal--"
                    // else progCode = "smartboard"
                    // window.api.send("toMain", {
                    //   UID: user.id,
                    //   Email: user.email,
                    //   App: user.expiry,
                    //   Code: progCode,
                    // })
                    // logout()
                  }}
                  // onGetExpiry={(value) => setExpiry(value)}
                  onGetExpiry={(value) =>
                    setExpiry(new Date(value).toLocaleDateString())
                  }
                />
              </CardStyle>
            )}
          </Grid>
          <Grid item xs={12}>
            <MButton
              variant="contained"
              round
              onClick={() => handleLogout()}
              sx={{ p: 1.5 }}
            >
              تسجيل الخروج
            </MButton>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  )
}
