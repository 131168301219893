// react
import { useState } from "react"
// material
import { LoadingButton } from "@mui/lab"
import { MIconButton, MButton } from "../../@material-extend"
import {
  Stack,
  TextField,
  InputAdornment,
  useMediaQuery,
  Typography
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
//
import * as Yup from "yup"
import { Icon } from "@iconify/react"
import { useSnackbar } from "notistack"
import eyeFill from "@iconify/icons-eva/eye-fill"
import closeFill from "@iconify/icons-eva/close-fill"
import eyeOffFill from "@iconify/icons-eva/eye-off-fill"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
// hooks
import useAuth from "../../../hooks/useAuth"

export default function RegisterForm({ onLogin }) {
  // breakpoint
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down("md"))
  // hooks
  const { register, user } = useAuth()
  // state
  const [showPassword, setShowPassword] = useState(false)
  // snackbar
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  // validation
  const FormSchema = Yup.object().shape({
    name: Yup.string()
    .required("يرجى إدخال الاسم")
      .min(2, "يجب أن يكون الاسم مكون من حرفين على الأقل")
      .max(50, "يجب أن لا يكون الاسم مكون من أكثر من 50 حرف."),
    email: Yup.string()
      .required("يرجى إضافة البريد الالكتروني")
      .email("الايميل غير صحيح"),
    password: Yup.string()
      .required("يرجى إدخال كلمة المرور")
      .test(
        "len",
        "يجب أن تكون كلمة المرور 6 أحرف على الأقل",
        (val) => val.length >= 6
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "كلمات المرور غير متطابقة!")
      .required("يرجى إدخال كلمة المرور"),
  })
  // reset values
  const defaultValues = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  }
  // form setup
  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: "onTouched",
    defaultValues,
    resolver: yupResolver(FormSchema),
  })

  const onSubmit = async (data, e) => {
    e.preventDefault()
    const { email, password, name } = data
    localStorage.setItem("displayName", name);
    register(email, password).then((result) => {
      if (result?.code) {
        console.error("ERROR: ", result)
        enqueueSnackbar(
          <div>
            <Typography variant="h6">
              {result.code === "auth/email-already-in-use"
                ? "إنّ هذا البريد تم استخدامه سابقاّ"
                : "فشلت عملية إنشاء حساب جديد!"}
            </Typography>
          </div>,
          {
            variant: "error",
            action: (key) => (
              <MIconButton
                size="small"
                color="inherit"
                onClick={() => closeSnackbar(key)}
              >
                <Icon icon={closeFill} width={24} height={24} />
              </MIconButton>
            ),
          }
        )
      }
    })
    reset()
  }

  // toggle icon
  const handleShowPassword = () => {
    setShowPassword((show) => !show)
  }

  return (
    <Stack justifyContent="right" sx={{ mt: 0 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={isTablet ? 1 : 3}>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                {...field}
                fullWidth
                placeholder="الاسم"
                error={Boolean(error)}
                helperText={error?.message}
                InputProps={{
                  sx: {
                    fontSize: isTablet ? ".8rem" : "inherit",
                  },
                }}
                FormHelperTextProps={{
                  style: {
                    fontSize: ".7rem",
                  },
                }}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                {...field}
                fullWidth
                placeholder="البريد الالكتروني"
                error={Boolean(error)}
                helperText={error?.message}
                InputProps={{
                  sx: {
                    fontSize: isTablet ? ".8rem" : "inherit",
                  },
                }}
                FormHelperTextProps={{
                  style: {
                    fontSize: ".7rem",
                  },
                }}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                size="small"
                control={control}
                fullWidth
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                placeholder="كلمة المرور"
                InputProps={{
                  sx: {
                    fontSize: isTablet ? ".8rem" : "inherit",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <MIconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </MIconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(error)}
                helperText={error?.message}
                FormHelperTextProps={{
                  style: {
                    fontSize: ".7rem",
                  },
                }}
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                size="small"
                control={control}
                fullWidth
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                placeholder="تأكيد كلمة المرور"
                InputProps={{
                  sx: {
                    fontSize: isTablet ? ".8rem" : "inherit",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <MIconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </MIconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(error)}
                helperText={error?.message}
                FormHelperTextProps={{
                  style: {
                    fontSize: ".7rem",
                  },
                }}
              />
            )}
          />
          <LoadingButton
            type="submit"
            variant="contained"
            size={isTablet ? "small" : "large"}
            loading={isSubmitting}
            color="secondary"
            sx={{ color: "white" }}
            //   disabled={!isDirty}
          >
            إنشاء الحساب
          </LoadingButton>
          <MButton
            color="secondary"
            onClick={() => {
              onLogin(false)
            }}
          >
            <Typography variant="minLinks" color="secondary.main">
              تسجيل الدخول
            </Typography>
          </MButton>
        </Stack>
      </form>
    </Stack>
  )
}
