import firebase from "firebase/app"
import "firebase/auth"

const config = {
  apiKey: "AIzaSyCB2ZHx9PqYjoOxFMdsaQ_3KaRmgmpbGYg",
  authDomain: "damma-7b938.firebaseapp.com",
  projectId: "damma-7b938",
  storageBucket: "damma-7b938.appspot.com",
  messagingSenderId: "301503477330",
  appId: "1:301503477330:web:db032ac6abfc167bffd18b",
  // measurementId: "G-7TWCWT6WDR",
}

//FIXME: delete me feedback credentials
// const config = {
//   apiKey: "AIzaSyBe3J6_P6IKCoubgu4FYBq-NJ8ICL7SpOI",
//   authDomain: "feedbacktest-d4d2b.firebaseapp.com",
//   projectId: "feedbacktest-d4d2b",
//   storageBucket: "feedbacktest-d4d2b.appspot.com",
//   messagingSenderId: "912718908636",
//   appId: "1:912718908636:web:dc2fc9110a46040291f819",
//   // measurementId: "G-ZDC0SEDEK1",
// }

let app = ""
if (!firebase.apps.length) app = firebase.initializeApp(config)
// console.log(firebase.app().name) // "[DEFAULT]"

export const auth = firebase.auth

export default app
