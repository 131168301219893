import axios from "axios"

export const setAxiosHeader = (id_token , refresh_token) => {
  if (id_token) {
    localStorage.setItem("id_token", id_token)
    localStorage.setItem("refresh_token", refresh_token)
    axios.defaults.headers.common.Authorization = `Bearer ${id_token}`
  } else {
    localStorage.removeItem("id_token")
    localStorage.removeItem("refresh_token")
    delete axios.defaults.headers.common.Authorization
  }
}

//NOTE: Deprecated ---------activateAPI------------
export const activateAPI = async (req) => {
  try {
    const response = await axios.post(
      "https://login.damma.app/webact/activate.php",
      req
    )
    if (response.data) {
      return response.data
    }
  } catch (error) {
    return { error }
  }
}
//NOTE: Deprecated ---------activateAPI------------

// Sample req body: { "UID" : "", "email": "", "displayName" : "" }
export const getAccount = async (req) => {
  try {
    const response = await axios.post(
      `https://api.damma.app/regOrLogin`,
      req
    )
    if (response.data) return response.data
  } catch (error) {
    return { error }
  }
}

// Check if account has been already subscribed
export const getSubscription = async (UID) => {
  try {
    const response = await axios.get(
      `https://api.damma.app/getSubscription/${UID}`
    )
    if (response.data)
      return response.data
  } catch (error) {
    return {error}
    // return {message: error.response.data.message}
  }
}

// allow user to subscribe 
// Sample req body: { "UID" : "" , "promoCode" : "" }
export const newSubscription = async (req) => {
   try {
     const response = await axios.post(
       "https://api.damma.app/newSubscription",
       req
     )
     if (response.data) 
       return response.data
   } catch (error) {
     return { error }
   }
}