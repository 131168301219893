import { useState, useEffect } from "react"
//
import { styled } from "@mui/material/styles"
import { Grid, Stack, Typography } from "@mui/material"
// hooks
import useAuth from "../../../hooks/useAuth"
//
import LoginForm from "./LoginForm"
import RegisterForm from "./RegisterForm"
//
import { Icon } from "@iconify/react"
import { useSnackbar } from "notistack"
import closeFill from "@iconify/icons-eva/close-fill"
import { MIconButton } from "../../@material-extend"
import googleBadge from "../../../assets/login/googleLogin.svg"
import appleBadge from "../../../assets/login/appleLogin.svg"
import Bg from "../../../assets/login/loginForm.svg"

// ----------------------------------------------------------------------
const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  minHeight: "100vh",
  background: `url(${Bg}) no-repeat`,
  backgroundPosition: "center",
  backgroundSize: "cover",
}))

const GridStyle = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(30),
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(20),
  },
}))

const ImageStyle = styled("img")(({ theme }) => ({
  "&:hover": {
    cursor: "pointer",
  },
}))
// ----------------------------------------------------------------------

export default function DesktopForm() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  //
  const [hidden, setHidden] = useState(false)
  const [imageIsReady, setImageIsReady] = useState(false)
  ///
  const { loginWithGoogle, loginWithApple } = useAuth()

  const handleLoginGoogle = async () => {
    try {
      await loginWithGoogle()
    } catch (error) {
      console.error("ERROR: ", error)
      if (error.code !== "auth/popup-closed-by-user")
        enqueueSnackbar(
          <div>
            <Typography variant="h6">
              عذراّ لقد فشل تسجيل الدخول عبر حساب غوغل
            </Typography>
          </div>,
          {
            variant: "error",
            action: (key) => (
              <MIconButton
                size="small"
                color="inherit"
                onClick={() => closeSnackbar(key)}
              >
                <Icon icon={closeFill} width={24} height={24} />
              </MIconButton>
            ),
          }
        )
    }
  }

  const handleLoginApple = async () => {
    try {
      await loginWithApple()
    } catch (error) {
      console.error("ERROR: ", error)
      if (error.code !== "auth/popup-closed-by-user")
        enqueueSnackbar(
          <div>
            <Typography variant="h6">
              عذراّ لقد فشل تسجيل الدخول عبر حساب آبل
            </Typography>
          </div>,
          {
            variant: "error",
            action: (key) => (
              <MIconButton
                size="small"
                color="inherit"
                onClick={() => closeSnackbar(key)}
              >
                <Icon icon={closeFill} width={24} height={24} />
              </MIconButton>
            ),
          }
        )
    }
  }
  ///

useEffect(() => {
  // console.log("mounted")
  const img = new Image()
  img.onload = () => {
    // when it finishes loading, update the component state
    setImageIsReady(true)
  }
  img.src = Bg // by setting an src, you trigger browser download
}, [])

  return (
    <RootStyle>
      {imageIsReady && (
        <Grid container justifyContent="space-evenly">
          <GridStyle item xs={4} sx={{ mr: 7, mt: 0 }}>
            {hidden ? (
              <LoginForm onLogin={() => setHidden(false)} />
            ) : (
              <RegisterForm onLogin={() => setHidden(true)} />
            )}
          </GridStyle>
          <GridStyle item xs={4} sx={{ mb: 8, ml: 5 }}>
            <Stack
              direction="column"
              justifyContent="center"
              spacing={1}
              sx={{ minHeight: "100%" }}  
            >
              <ImageStyle
                width="85%"
                alt="Sign in with Google"
                src={googleBadge}
                onClick={() => handleLoginGoogle()}
              />
              <ImageStyle
                width="85%"
                alt="Sign in with Apple"
                src={appleBadge}
                onClick={() => handleLoginApple()}
              />
            </Stack>
          </GridStyle>
        </Grid>
      )}
    </RootStyle>
  )
}
