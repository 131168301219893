import { forwardRef } from "react"
// material
import { Button } from "@mui/material"
// nodejs library to set properties for components
import PropTypes from "prop-types"

let MButton = forwardRef((props, ref) => {
  let { round, children, size, color, padding, ...rest } = props
  return (
    <Button
      sx={{
        borderRadius: round ? "30px" : "unset",
        p: padding,
        "& , &:hover": {
          backgroundColor: color,
        },
      }}
      size={size}
      {...rest}
    >
      {children}
    </Button>
  )
})

MButton.prototype = {
  padding: PropTypes.number,
  color: PropTypes.string,
  round: PropTypes.bool,
  children: PropTypes.node,
  size: PropTypes.oneOf(["small", "large", "medium"]),
}

export default MButton
