// react
import { useState } from "react"
// next
import { NavLink } from "react-router-dom"
// material
import { styled } from "@mui/material/styles"
import { Box, Button, Container, Typography } from "@mui/material"
// components
import Page from "../components/Page"
import ResetPasswordForm from "../components/_external-pages/login/ResetPasswordForm"
// assets
import { SentIcon } from "../assets"

// ----------------------------------------------------------------------
const RootStyle = styled(Page)(({ theme }) => ({
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
  minHeight: "100vh",
}))
// ----------------------------------------------------------------------

///
const context = {
  arabic: {
    title: "نسيت كلمة المرور؟",
    body: "يرجى إدخال عنوان بريدك الإلكتروني وسنقوم بإرسال رابط يسمح لك بإدخال كلمة المرور الجديدة.",
    submitBtn: "إرسال",
    backBtn: "رجوع",
  },
}
///

export default function ResetPassword() {
  const [email, setEmail] = useState("")
  const [sent, setSent] = useState(false)
  return (
    <RootStyle title="إنشاء كلمة مرور جديدة | ضمّة">
      <Container maxWidth="lg">
        <Box sx={{ maxWidth: 480, mx: "auto" }}>
          {!sent ? (
            <>
              <Typography variant="h3" paragraph color="primary.main">
                {context.arabic.title}
              </Typography>
              <Typography color="blueText" sx={{ mb: 5 }}>
                {context.arabic.body}
              </Typography>
              <ResetPasswordForm
                onSent={() => setSent(true)}
                onGetEmail={(value) => setEmail(value)}
              />
              <NavLink to="/login">
                <Button fullWidth size="large" sx={{ mt: 1 }}>
                  {context.arabic.backBtn}
                </Button>
              </NavLink>
            </>
          ) : (
            <Box sx={{ textAlign: "center" }}>
              <SentIcon sx={{ mb: 5, mx: "auto", height: 160 }} />
              <Typography variant="h3" gutterBottom color="secondary.main">
                تم إرسال الطلب بنجاح!
              </Typography>
              <Typography color="primary.main">
                لقد أرسلنا رسالة تأكيد إلى &nbsp;
                <strong>{email}</strong>
                <br />
                يرجى التحقق من بريدك الإلكتروني.
              </Typography>
              <NavLink to="/login">
                <Button size="large" variant="contained" sx={{ mt: 5 }}>
                  {context.arabic.backBtn}
                </Button>
              </NavLink>
            </Box>
          )}
        </Box>
      </Container>
    </RootStyle>
  )
}
