// routes
import Routes from "./routes"
// theme
import ThemeConfig from "./theme"
import GlobalStyles from "./theme/globalStyles"
// components
import RtlLayout from "./components/RtlLayout"
import { AuthProvider } from "./contexts/FirebaseContext"
import NotistackProvider from "./components/NotistackProvider"
import ThemePrimaryColor from "./components/ThemePrimaryColor"
// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <NotistackProvider>
            <GlobalStyles />
            <AuthProvider>
              <Routes />
            </AuthProvider>
          </NotistackProvider>
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
  )
}
