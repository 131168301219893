// react
import { useState } from "react"
import { NavLink } from "react-router-dom"
// material
import { LoadingButton } from "@mui/lab"
import { MIconButton, MButton } from "../../@material-extend"
import {
  Stack,
  Link,
  TextField,
  InputAdornment,
  useMediaQuery,
  Typography,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
//
import * as Yup from "yup"
import { Icon } from "@iconify/react"
import { useSnackbar } from "notistack"
import closeFill from "@iconify/icons-eva/close-fill"
import eyeFill from "@iconify/icons-eva/eye-fill"
import eyeOffFill from "@iconify/icons-eva/eye-off-fill"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
// hooks
import useAuth from "../../../hooks/useAuth"

export default function LoginForm({ onLogin }) {
  // breakpoint
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down("md"))
  // hooks
  const { login } = useAuth()
  // state
  const [showPassword, setShowPassword] = useState(false)
  // snackbar
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  // validation
  const FormSchema = Yup.object().shape({
    email: Yup.string()
      .required("يرجى إضافة البريد الالكتروني")
      .email("الايميل غير صحيح"),
    password: Yup.string().required("يرجى إدخال كلمة المرور"),
  })
  // reset values
  const defaultValues = {
    email: "",
    password: "",
  }
  // form setup
  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: "onTouched",
    defaultValues,
    resolver: yupResolver(FormSchema),
  })

  const onSubmit = async (data, e) => {
    e.preventDefault()
    const { email, password } = data
    try {
      await login(email, password)
        .then(() => {
          // console.log("user: ", user)
        })
        .catch((error) => {
          console.error("ERROR: ", error)
          enqueueSnackbar(
            <div>
              <Typography variant="h6">
                {error.code === "auth/wrong-password"
                  ? "كلمة المرور خاطئة"
                  : error.code === "auth/user-not-found"
                  ? "هناك خطأ في العثور على هذا الحساب"
                  : ""}
              </Typography>
            </div>,
            {
              variant: "error",
              action: (key) => (
                <MIconButton
                  size="small"
                  color="inherit"
                  onClick={() => closeSnackbar(key)}
                >
                  <Icon icon={closeFill} width={24} height={24} />
                </MIconButton>
              ),
            }
          )
        })
    } catch (error) {
      console.error("ERROR: ", error)
    }
    reset()
  }

  // toggle icon
  const handleShowPassword = () => {
    setShowPassword((show) => !show)
  }

  return (
    <Stack justifyContent="right" sx={{ mt: 5 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1}>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                size={isTablet ? "small" : "medium"}
                {...field}
                fullWidth
                placeholder="البريد الالكتروني"
                error={Boolean(error)}
                helperText={error?.message}
                InputProps={{
                  sx: {
                    fontSize: isTablet ? "1rem" : "inherit",
                  },
                }}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                size={isTablet ? "small" : "medium"}
                control={control}
                fullWidth
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                placeholder="كلمة المرور"
                InputProps={{
                  sx: {
                    fontSize: isTablet ? "1rem" : "inherit",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <MIconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </MIconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
          <NavLink to="/reset-password">
            <Link
              href="/reset-password"
              sx={{ ...theme.typography.minLinks }}
              target=""
            >
              نسيت كلمة المرور ؟
            </Link>
          </NavLink>
          <LoadingButton
            type="submit"
            variant="contained"
            size={isTablet ? "small" : "large"}
            loading={isSubmitting}
            color="secondary"
            sx={{ color: "white" }}
            //   disabled={!isDirty}
          >
            تسجيل الدخول
          </LoadingButton>
          <MButton
            color="secondary"
            onClick={() => {
              onLogin(true)
            }}
          >
            <Typography variant="minLinks" color="secondary.main">
              إنشاء حساب جديد
            </Typography>
          </MButton>
        </Stack>
      </form>
    </Stack>
  )
}
