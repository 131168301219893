import PropTypes from "prop-types"
import { createContext, useEffect, useReducer, useState } from "react"
import {} from "../config"
import firebase from "firebase/app"
import { getAccount, setAxiosHeader } from "network/api"
// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
}

const reducer = (state, action) => {
  if (action.type === "INITIALISE") {
    const { isAuthenticated, user } = action.payload
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    }
  }
  return state
}

const AuthContext = createContext({
  ...initialState,
  method: "firebase",
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  loginWithApple: () => Promise.resolve(),
  logout: () => Promise.resolve(),
})

AuthProvider.propTypes = {
  children: PropTypes.node,
}

function AuthProvider({ children }) {
  const [profile, setProfile] = useState(null)
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          user
            .getIdToken()
            .then(async(idToken) => {
              if (idToken) {
                await setAxiosHeader(idToken, user.refreshToken)
                const displayName = localStorage.getItem("displayName")
                const req = {
                  UID: user.uid,
                  email: user.email,
                  displayName: user.displayName || displayName,
                }
                getAccount(req).then((data) => {
                  dispatch({
                    type: "INITIALISE",
                    payload: {
                      isAuthenticated: true,
                      user,
                    },
                  })
                  localStorage.setItem("email", user.email)
                })
              }
            })
            .catch((error) => {
              console.log("AUTH ERROR: ", error)
            })
        } else {
          dispatch({
            type: "INITIALISE",
            payload: { isAuthenticated: false, user: null },
          })
          localStorage.setItem("email", "")
        }
      }),
    [dispatch]
  )

  const login = (email, password) =>
    firebase.auth().signInWithEmailAndPassword(email, password)

  const loginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    provider.addScope("email")
    provider.setCustomParameters({
      prompt: "select_account",
    })
    return firebase.auth().signInWithPopup(provider)
  }

  const loginWithApple = () => {
    const provider = new firebase.auth.OAuthProvider("apple.com")
    provider.addScope("email")
    provider.addScope("name")
    return firebase.auth().signInWithPopup(provider)
  }

  //TODO: inform user that the system sent him a confirmation email to verify his account (Alert)
  const register = async (email, password) => {
    try {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(() => {
          verifyEmail().then(() => {
            return true
          })
        })
    } catch (error) {
      return error
    }
  }

  const logout = async () => {
    await firebase.auth().signOut()
    localStorage.setItem("email", "")
  }

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email)
  }

  const verifyEmail = async () => {
    await firebase.auth().currentUser.sendEmailVerification()
  }

  const auth = { ...state.user }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "firebase",
        user: {
          id: auth.uid,
          email: auth.email,
          photoURL: auth.photoURL || profile?.photoURL,
          displayName: auth.displayName || profile?.displayName,
          phoneNumber: auth.phoneNumber || profile?.phoneNumber || "",
        },
        login,
        register,
        loginWithGoogle,
        loginWithApple,
        logout,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
