import PropTypes from "prop-types"
// material
import { TextField, Stack, Typography } from "@mui/material"
import { MIconButton } from "../../@material-extend"
import { LoadingButton } from "@mui/lab"
// hooks
import useAuth from "../../../hooks/useAuth"
//
import * as Yup from "yup"
import { Icon } from "@iconify/react"
import { useSnackbar } from "notistack"
import closeFill from "@iconify/icons-eva/close-fill"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"

ResetPasswordForm.propTypes = {
  onSent: PropTypes.func,
  onGetEmail: PropTypes.func,
}

export default function ResetPasswordForm({ onSent, onGetEmail }) {
  const { resetPassword } = useAuth()
  // snackbar
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  // validation
  const FormSchema = Yup.object().shape({
    email: Yup.string()
      .required("يرجى إضافة البريد الالكتروني")
      .email("الايميل غير صحيح"),
  })
  // reset values
  const defaultValues = {
    email: "",
    password: "",
  }
  // form setup
  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = useForm({
    mode: "onTouched",
    defaultValues,
    resolver: yupResolver(FormSchema),
  })

  const onSubmit = async (data, e) => {
    e.preventDefault()
    const { email } = data
    try {
      await resetPassword(email)
      onSent()
      onGetEmail(email)
    } catch (error) {
      console.error("ERROR: ", error)
      enqueueSnackbar(
        <div>
          <Typography variant="h6">
            {error.code === "auth/user-not-found"
              ? "هذا الحساب غير موجود أو صالح"
              : "عذراً هناك خطأ، يرجى المحاولة لاحقاً"}
          </Typography>
        </div>,
        {
          variant: "error",
          action: (key) => (
            <MIconButton
              size="small"
              color="inherit"
              onClick={() => closeSnackbar(key)}
            >
              <Icon icon={closeFill} width={24} height={24} />
            </MIconButton>
          ),
        }
      )
    }
    reset()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              placeholder="البريد الالكتروني"
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          loading={isSubmitting}
          color="secondary"
          disabled={!isDirty}
        >
          إرسال
        </LoadingButton>
      </Stack>
    </form>
  )
}
