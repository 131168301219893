//
import PropTypes from "prop-types"
// material
import { MIconButton } from "../../@material-extend"
import { LoadingButton } from "@mui/lab"
import { Stack, Link, TextField, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
// API
import { activateAPI  , newSubscription} from "../../../network/api"
//
import { Icon } from "@iconify/react"
import { useSnackbar } from "notistack"
import closeFill from "@iconify/icons-eva/close-fill"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, Controller } from "react-hook-form"
// hooks
import useAuth from "../../../hooks/useAuth"

const RootStyle = styled("div")(({ theme }) => ({
  minWidth: 350,
  width: "100%",
}))

export default function PromoForm({ onSent, onGetExpiry }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { user, isAuthenticated  , logout} = useAuth()
  // validation
  const FormSchema = Yup.object().shape({
    promo: Yup.string()
      .required("يرجى إضافة رمز التفعيل")
      .test(
        "len",
        "هناك خطأ في تكوين رمز التفعيل",
        (val) => val.length === 19 || val.length === 16
      ),
  })
  // reset values
  const defaultValues = {
    promo: "",
  }
  // form setup
  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = useForm({
    mode: "onTouched",
    defaultValues,
    resolver: yupResolver(FormSchema),
  })

  const onSubmit = async (data, e) => {
    e.preventDefault()
    const { promo } = data
    try {
      // const req = {
      //   progCode: "yoyo",
      //   serialNo: promo,
      //   accountId: user.email,
      // }
      const req = {
        UID: user.id,
        promoCode: promo,
        // progCode: 'yoyo' // not covered from server
      }
      if (user && isAuthenticated) {
        //NOTE: this code describes using new APIs
        newSubscription(req).then((data) => {
          if (data.error?.message || data?.validUntil === null) {
                enqueueSnackbar(
                  <div>
                    <Typography variant="h6">
                      رمز التفعيل غير صالح للاستخدام
                    </Typography>
                  </div>,
                  {
                    variant: "error",
                    action: (key) => (
                      <MIconButton
                        size="small"
                        color="inherit"
                        onClick={() => closeSnackbar(key)}
                      >
                        <Icon icon={closeFill} width={24} height={24} />
                      </MIconButton>
                    ),
                  }
                )
          } else {
            // user.promo = data.serialNo //NOTE: not retrieved from server
            // user.progCode = res.progCode //NOTE: not retrieved from server
            user.expiry = data.validUntil
            let progCode = "personal--"
            if (data.role === "class" || data.role === "teacher")
              progCode = "smartboard"
            // if (user.progCode == "yoyo") progCode = "personal--"
            // else progCode = "smartboard"
            window.api.send("toMain", {
              UID: user.id,
              Email: user.email,
              App: user.expiry,
              Code:progCode,
              Token: localStorage.getItem('id_token'),
              RefreshToken: localStorage.getItem('refresh_token')
            })
            logout()
            onGetExpiry(data.validUntil)
            onSent()
          }
        }).catch((error) => {
          console.error("Error" , error)
        })
        //NOTE: this code describes using new APIs
        // activateAPI(req).then((res) => {
        //   if (res.success === "True") {
        //     user.promo = res.serialNo
        //     user.progCode = res.progCode
        //     user.expiry = res.expiry
        //     //  console.log("user: ", user)
        //      let progCode = ""
        //      if (user.progCode == "yoyo") progCode = "personal--"
        //      else progCode = "smartboard"
        //      window.api.send("toMain", {
        //        UID: user.id,
        //        Email: user.email,
        //        App: user.expiry,
        //        Code: progCode,
        //      })
        //      logout()
        //     onGetExpiry(res.expiry)
        //     onSent()
        //   } else if (res.success === "False") {
        //     enqueueSnackbar(
        //       <div>
        //         <Typography variant="h6">
        //           رمز التفعيل غير صالح للاستخدام
        //         </Typography>
        //       </div>,
        //       {
        //         variant: "error",
        //         action: (key) => (
        //           <MIconButton
        //             size="small"
        //             color="inherit"
        //             onClick={() => closeSnackbar(key)}
        //           >
        //             <Icon icon={closeFill} width={24} height={24} />
        //           </MIconButton>
        //         ),
        //       }
        //     )
        //   }
        // })
      }
    } catch (error) {
      console.error("ERROR: ", error)
    }
    reset()
  }

  return (
    <RootStyle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" spacing={2}>
          <Controller
            name="promo"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                placeholder="ادخل رمز التفعيل الخاص بك هنا..."
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
          <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            loading={isSubmitting}
            color="secondary"
            sx={{ color: "white" }}
            disabled={!isDirty}
          >
            تفعيل
          </LoadingButton>
        </Stack>
      </form>
    </RootStyle>
  )
}

PromoForm.propTypes = {
  onSent: PropTypes.func,
  onGetExpiry: PropTypes.func,
}
