import { styled } from "@mui/material/styles"
import { Container } from "@mui/material"
import { useHistory } from "react-router-dom"
import Page from "../components/Page"
import DesktopForm from "../components/_external-pages/login/DesktopForm"
import { useEffect } from "react"
import useAuth from "../hooks/useAuth"
import Bg from "../assets/login/doodle.svg"

// ----------------------------------------------------------------------
const RootStyle = styled(Page)(({ theme }) => ({
  background: `url(${Bg}) no-repeat`,
  backgroundPosition: "center",
  backgroundSize: "cover",
}))
// ----------------------------------------------------------------------

export default function Login() {
  const history = useHistory()
  const { user, isAuthenticated } = useAuth()
  useEffect(() => {
    if (isAuthenticated) {
      history.push("/profile")
    }
  }, [isAuthenticated, user])

  return (
    <RootStyle title="تسجيل الدخول | ضمّة">
      <Container maxWidth="lg">
        <DesktopForm />
      </Container>
    </RootStyle>
  )
}
