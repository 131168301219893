import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
//Pages
import Login from "./pages/Login"
import Profile from "./pages/Profile"
import ResetPassword from "./pages/ResetPassword"

export default function Main(props) {
  return (
    <Router>
      <div className="container">
        <Switch>
          <Route exact path="/" component={(props) => <Login {...props} />} />
          <Route path="/login" component={(props) => <Login {...props} />} />
          <Route
            path="/profile"
            component={(props) => <Profile {...props} />}
          />
          <Route
            path="/reset-password"
            component={(props) => <ResetPassword {...props} />}
          />
        </Switch>
      </div>
    </Router>
  )
}
